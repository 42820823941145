 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人">
				<el-input class="el_input" v-model="form.app_user_tel" placeholder="提现人手机号码搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="出金卡号">
				<el-input class="el_input" v-model="form.platform_card_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="出金流水">
				<el-input class="el_input" v-model="form.platform_bank_ra_num" placeholder="出金流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款卡号">
				<el-input class="el_input" v-model="form.user_card_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人">
				<el-input class="el_input" v-model="form.admin_user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="提现状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="提现中" value="1"></el-option>
					<el-option label="已完成" value="2"></el-option>
					<el-option label="提现失败" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="提现类型">
				<el-select class="el_input" v-model="form.type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="现金" value="1"></el-option>
					<el-option label="燃油费" value="2"></el-option>
					<el-option label="过路费" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="业务类型">
				<el-select class="el_input" v-model="form.read_type" clearable>
					<el-option label="业务1" value="real_time"></el-option>
					<el-option label="业务2" value="after_time"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="发起时间/流水编号/运单编号">
					<template slot-scope="scope">
						<div>发起: {{scope.row.creat_time_text}}</div>
						<div>流水: {{scope.row.ra_num}}</div>
						<div>运单: {{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人">
					<template slot-scope="scope">
						<div>姓名: {{scope.row.app_user_info.name}}</div>
						<div>手机: {{scope.row.app_user_info.tel}}</div>
						<div>身份: {{scope.row.user_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人">
					<template slot-scope="scope">
						<div>姓名: {{scope.row.admin_user_info.name}}</div>
						<div>手机: {{scope.row.admin_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="出金用银行卡">
					<template slot-scope="scope">
						<div>银行: {{scope.row.platform_bank_cname}}</div>
						<div>卡号: {{scope.row.platform_card_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款银行卡">
					<template slot-scope="scope">
						<div>银行: {{scope.row.user_bank_cname}}</div>
						<div>卡号: {{scope.row.user_card_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="金额/状态/结束时间">
					<template slot-scope="scope">
						<div>{{scope.row.much}} / {{scope.row.type_text}}</div>
						<div>{{scope.row.status_text}}</div>
						<div>{{scope.row.end_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="出金流水编号">
					<template slot-scope="scope">
						<div>流水号: {{scope.row.platform_bank_ra_num}}</div>
						<div>备&nbsp;&nbsp;&nbsp;注: {{scope.row.mark}}</div>
						<div>
							操&nbsp;&nbsp;&nbsp;作: 
							<el-button @click="show_voucher(scope.row.platform_bank_ra_voucher)" size="mini" type="text">打款凭证</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 凭证弹窗 -->
		<el-dialog
			top="2vh"
			width="800px"
			title="打款凭证"
			:visible.sync="voucher.is_show"
		>
			<el-image class="img" :src="voucher.src" :previewSrcList="voucher.srclist" :z-index = 3000>
			</el-image>
			<!-- <img :src="voucher.src" style="width:100%"> -->
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
				//搜索条件
				form: {
					ra_num:'',//流水编号
					truck_tord_num:'',//运单编号
					app_user_tel:'',//提现人手机
					platform_card_num:'',//出金卡号
					platform_bank_ra_num:'',//出金流水号
					user_card_num:'',//收款卡号
					admin_user_tel:'',//操作人手机
					status:'',//提现状态
					type:'',//提现类型
					read_type:'real_time',//读取方式 real_time after_time
					mark:'',//备注
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//关系凭证弹出层
				voucher:{

					//是否显示
					is_show:false,
					
					//地址
					src:'',

					srclist:[]
				}
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//显示打款凭证
			show_voucher(voucher){
				if(voucher){
					this.voucher.src=this.$my.qiniu.make_src('tordrealtimeoutcashvoucher',voucher),
					this.voucher.srclist[0]=this.$my.qiniu.make_src('tordrealtimeoutcashvoucher',voucher),
					console.log(this.voucher)
					this.voucher.is_show=true;
				}
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//流水编号
					truck_tord_num:'',//运单编号
					app_user_tel:'',//提现人手机
					platform_card_num:'',//出金卡号
					platform_bank_ra_num:'',//出金流水号
					user_card_num:'',//收款卡号
					admin_user_tel:'',//操作人手机
					status:'',//提现状态
					type:'',//提现类型
					read_type:'real_time',//读取方式 real_time after_time
					mark:'',//备注
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'tord_out_cash_list_admin',
						is_get_app_user_info:1,
						is_get_admin_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//用户类型(1:收款人,2:司机)
							switch(item.user_type){
								case '1':item.user_type_text='收款人';break;
								case '2':item.user_type_text='司机';break;
							}

							//状态(1:提现中,2:已完成,3提现失败)
							switch(item.status){
								case '1':item.status_text='提现中';break;
								case '2':item.status_text='已完成';break;
								case '3':item.status_text='提现失败';break;
							}

							//类型(1:现金,2:燃油费,3:过路费)
							switch(item.type){
								case '1':item.type_text='现金';break;
								case '2':item.type_text='燃油费';break;
								case '3':item.type_text='过路费';break;
							}

							//完成时间
							if(item.status!=1){
								item.end_time_text=this.$my.other.totime(item.end_time);
							}else item.end_time_text='';
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>
	
<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>